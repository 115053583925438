<template>
  <div class="container">
    <div class="dropdown" style="display: flex; justify-content: center">
      <button
        class="dropdownbtn btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="margin: 0px 5px 10px 0px"
      >
        {{ difficulty }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <a class="dropdown-item" @click="changeDifficulty('Easy')">Easy</a>
        <a class="dropdown-item" @click="changeDifficulty('Normal')">Normal</a>
      </ul>

      <button
        class="dropdownbtn btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style="margin: 0px 0px 10px 0px"
      >
        {{ currentTitle }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li v-for="(item, index) in contentList" :key="index">
          <a class="dropdown-item" @click="changeContent(index)">{{
            item.title
          }}</a>
        </li>
      </ul>
    </div>

    <div class="box" v-if="difficulty == 'Easy'">
      <div class="key-word">
        <p>{{ problem.join(" / ") }}</p>
      </div>
      <div class="input-answer">
        <template v-for="(item, index) in blankSentence" :key="index">
          <input
            v-if="blank[`${index}index`] === blankSentence[index]"
            type="text"
            :class="{
              answercorrect: correct[index],
              answer: !correct[index],
              wrong: wrong[index],
            }"
            :readonly="correct[index]"
            :disabled="correct[index]"
            :style="{ width: getWidth(item) }"
            ref="index"
            v-model="answer[index]"
            @keyup.enter="checkAnswer(item, index)"
            @keydown.tab="checkAnswer(item, index)"
          />
          <span v-else>{{ item }}</span>
        </template>
      </div>
    </div>

    <Normal
      @goNext="nextProblem"
      v-bind:sentence="sentence"
      :translation="translation"
      :key="sentence"
      v-if="difficulty === 'Normal'"
    ></Normal>
  </div>
</template>

<script>
import { getTextWidth } from "get-text-width";

//array shuffle function
function shuffle(array) {
  array.sort(() => Math.random() - 0.5);
}

import contentList from "../assets/contentlist.json";
import NormalVue from "./Normal.vue";

export default {
  //This is the story of how the Roeblings built the Brooklyn Bridge.
  name: "Main",
  data() {
    return {
      sentence: "",
      keyword: [],
      blankSentence: [], //sentence split 해놓은 배열
      blank: new Map([]), //비어있는 문자 맵
      answer: [], //input v-model
      problem: [], // 키워드 표시 배열
      correct: [], //맞은지 안맞은지
      count: 0, //모든문제 맞춘지 확인하는   변수 aka편법
      sentenceCount: 0, //allsentence index
      allsentence: [], //모든 영문장
      indexRef: [], //input ref
      wrong: [], //shake animation
      items: [], //본문 목록
      contentList: contentList, //본문 목록
      currentTitle: "", //현재 본문 제목
      translateSen: [], //번역본
      translation: "", //번역
      difficulty: "Easy", //난이도
    };
  },
  components: {
    Normal: NormalVue,
  },
  methods: {
    changeDifficulty(difficulty) {
      this.difficulty = difficulty;
    },
    getKeyword() {
      var shuffle_sen = this.sentence.replace(",", " ,").split(" ");
      var problem = [];
      var keyword = [];
      var ranpick = shuffle_sen.length - 3; //최소 3개는 남겨두고 선택
      for (let i = 1; i <= Math.floor(Math.random() * ranpick) + 1; i++) {
        var word = Math.floor(Math.random() * ranpick) + 1;
        while (
          keyword.includes(word) ||
          shuffle_sen[word] === "," ||
          shuffle_sen[word] === `"`
        ) {
          // 중복확인
          word = Math.floor(Math.random() * ranpick) + 1;
        }
        keyword.push(word);
        problem.push(shuffle_sen[word]);
        console.log(word, shuffle_sen[word]);
        this.blank[`${word}index`] = shuffle_sen[word];
      }
      shuffle(problem);
      this.blankSentence = shuffle_sen; //문자가 비어있는지 확인용
      //map remove undefined value
      this.problem = problem;
      this.count = 0;
    },

    nextProblem() {
      if (this.sentenceCount < this.allsentence.length) {
        this.sentenceCount++;
        this.sentence = this.allsentence[this.sentenceCount];
        this.translation = this.translateSen[this.sentenceCount];
        this.reset();
        this.getKeyword();
      } else {
        alert("모든 문제를 풀었습니다.");
      }
    },

    //check all problem is correct
    checkAll() {
      if (this.count === this.problem.length) {
        this.nextProblem();
      }
    },

    checkAnswer(item, index) {
      //   console.log(item, index, this.answer[index]);
      if (item == this.answer[index]) {
        this.count++;
        this.correct[index] = true;
        this.checkAll(index);
        console.log("correct");
      } else {
        this.wrong[index] = true;
        setTimeout(() => {
          this.wrong[index] = false;
        }, 1000);
        console.log("wrong");
      }
    },

    getWidth(item) {
      return getTextWidth(item) + 25 + "px";
    },

    getLessonData() {
      /* eslint-disable */
      for (var i = 0; i < this.contentList.length; i++) {
        this.items.push({
          title: contentList[i].title,
          content: contentList[i].content,
          type: contentList[i].type,
          translation: contentList[i].translation,
        });
      }
      this.currentTitle = this.items[0].title;
      const lessonDataArray = this.items[0].content
        .replace("’", "'")
        .split(".");
      this.translateSen = this.items[0].translation.split(".");
      this.translation = this.translateSen[0];
      return lessonDataArray;
    },
    reset() {
      this.answer = [];
      this.correct = [];
      this.indexRef = [];
      this.blank = [];
      this.blankSentence = [];
    },
    changeContent(index) {
      if (this.currentTitle == this.items[index].title) {
        return;
      }

      this.currentTitle = this.items[index].title;
      const lessonDataArray = this.items[index].content
        .replace("’", "'")
        .split(".");
      this.translateSen = this.items[index].translation.split(".");
      this.allsentence = lessonDataArray;
      this.sentence = this.allsentence[this.sentenceCount];
      this.translation = this.translateSen[this.sentenceCount];
      this.reset();
      this.sentenceCount = 0;
      this.getKeyword();
    },
  },

  created: function () {
    this.allsentence = this.getLessonData();
    this.sentence = this.allsentence[this.sentenceCount];
    this.getKeyword();
  },
  mounted() {
    console.log(this.$refs);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html {
  background: #badffc;
}
</style>
<style scoped>
.wrong {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-4px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.dropdownbtn {
  background-color: #fff;
  color: black;
  border-radius: 0px;
}

@media screen and (min-width: 1100px) {
  .box {
    min-height: 200px;
    width: 90%;
    border-radius: 25px;
    display: inline-block;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 15px 0 rgba(0, 0, 0, 0.09);
    overflow: hidden;
    background: #fff;
    margin: 0px;
  }

  .key-word {
    min-height: 100px;
    min-width: 450px;
    display: flex;
    background: #eef5fb;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .input-answer {
    min-height: 100px;
    min-width: 450px;
    display: flex;
    background: #fff;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  .answer {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    padding: 0px 3px 0px 3px;
  }

  .answercorrect {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    color: #139470;
    padding: 0px 3px 0px 3px;
  }

  p {
    margin: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
  }

  span {
    margin: 0px 4px 0px 4px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
  }
}

@media screen and (max-width: 1100px) {
  html,
  body {
    width: 100%;
    height: 100%;
  }
  .box {
    width: 100%;
    border-radius: 25px;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 15px 0 rgba(0, 0, 0, 0.09);
    overflow: hidden;
    background: #fff;
  }

  .key-word {
    min-height: 70px;
    width: 100%;
    display: flex;
    background: #eef5fb;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
  }

  .input-answer {
    min-height: 70px;
    width: 100%;
    display: flex;
    background: #fff;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px 10px 0px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  .answer {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    padding: 0px 3px 0px 3px;
  }

  .answercorrect {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    color: #139470;
    padding: 0px 3px 0px 3px;
  }

  p {
    margin: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 14pt;
  }

  span {
    margin: 0px 4px 0px 4px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 14pt;
  }
}
</style>
