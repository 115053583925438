<template>
  <div class="box">
    <div class="key-word">
      <span>{{ translation }}</span>
      <span style="margin: 3px 0px 0px 0px" :key="this.sentence">{{
        hint.join(" / ")
      }}</span>
    </div>
    <div class="input-answer">
      <input v-model="answer" type="text" @keyup.enter="checkAnswer()" />
    </div>
  </div>
</template>

<script>
function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  name: "Normal",
  data() {
    return {
      hint: [],
      answer: "", // v-model input
    };
  },
  props: {
    sentence: String,
    translation: String,
  },
  methods: {
    getHint() {
      let hint = this.sentence.split(" ");
      let max = rand(1, Math.floor(hint.length / 2));
      for (let i = 0; i < max; i++) {
        let index = rand(0, hint.length - 1);
        while (hint[index] === ",") {
          index = rand(0, hint.length - 1);
        }
        this.hint.push(hint[index]);
      }
    },
    checkAnswer() {
      if (
        this.answer.trim().toLowerCase() == this.sentence.trim().toLowerCase()
      ) {
        this.$emit("goNext");
        this.answer = "";
        this.hint = [];
        this.getHint();
      } else {
        console.log("wrong");
      }
    },
  },
  created() {
    this.getHint();
  },
};
</script>
<style scoped>
input {
  width: 80%;
  border: 0px solid #000;
  border-bottom-width: 1px;
}
.wrong {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-4px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.dropdownbtn {
  background-color: #fff;
  color: black;
  border-radius: 0px;
}

@media screen and (min-width: 1100px) {
  .box {
    min-height: 200px;
    min-width: 450px;
    border-radius: 25px;
    display: inline-block;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 15px 0 rgba(0, 0, 0, 0.09);
    overflow: hidden;
    background: #fff;
    margin: 0px 50px 0px 50px;
  }

  .key-word {
    min-height: 100px;
    min-width: 450px;
    display: flex;
    background: #eef5fb;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .input-answer {
    min-height: 100px;
    min-width: 450px;
    display: flex;
    background: #fff;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  * {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }
  .answercorrect {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    color: #139470;
    padding: 0px 3px 0px 3px;
  }

  p {
    margin: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
  }

  span {
    margin: 0px 4px 0px 4px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
  }
}

@media screen and (max-width: 1100px) {
  html,
  body {
    width: 100%;
    height: 100%;
  }
  .box {
    width: 100%;
    border-radius: 25px;
    display: inline-block;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 6px 15px 0 rgba(0, 0, 0, 0.09);
    overflow: hidden;
    background: #fff;
  }

  .key-word {
    min-height: 70px;
    width: 100%;
    display: flex;
    background: #eef5fb;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;
  }

  .input-answer {
    min-height: 70px;
    width: 100%;
    display: flex;
    background: #fff;
    text-align: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0px 10px 0px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  .answercorrect {
    height: auto;
    border-radius: 7px;
    margin: 0px 7px 0px 7px;
    background: #d8e9fb;
    border: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 15pt;
    color: #139470;
    padding: 0px 3px 0px 3px;
  }

  p {
    margin: 0px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 14pt;
  }

  span {
    margin: 0px 4px 0px 4px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 14pt;
  }
}
</style>
    